<template>
  <div>
    <lazy-library-title text="Mortgage Calculator" tag="h3"></lazy-library-title>
    <div class="tw-mt-5 tw-grid tw-grid-cols-12">
      <common-inputs-nc-currency-input
        v-model:price="model.price"
        class="tw-col-span-12 md:tw-col-span-6"
        label="Property Price"
        :is-currency="true"
      ></common-inputs-nc-currency-input>
    </div>
    <div class="tw-mt-5 tw-grid tw-grid-cols-12">
      <div class="tw-col-span-12 tw-flex tw-flex-row tw-content-center md:tw-col-span-6">
        <common-inputs-nc-currency-input
          v-model:price="model.deposit_fee"
          label="Deposit"
          class="tw-w-full"
          :is-currency="true"
          @keyup="onFeeChanged"
        ></common-inputs-nc-currency-input>
      </div>
      <div class="tw-col-span-12 tw-flex tw-flex-row tw-content-center tw-pt-6 md:tw-col-span-6 md:tw-pl-16">
        <doughnut-chart :percentage="depositPercentage" />
      </div>
    </div>
    <div class="tw-mt-5 tw-grid tw-grid-cols-12 tw-gap-x-3">
      <div class="tw-col-span-12 tw-flex tw-flex-row tw-content-center md:tw-col-span-6">
        <nc-input
          v-model="model.annual_interest_rate"
          label="Annual Interest"
          type="number"
          class="tw-w-full"
          input-icon-value="%"
          input-icon="right"
        ></nc-input>
      </div>
      <div class="tw-col-span-12 tw-flex tw-flex-row tw-content-center md:tw-col-span-6 md:tw-pl-16">
        <nc-input
          v-model="model.repayment_period"
          label="Repayment Period (Years)"
          type="number"
          class="tw-w-full"
        ></nc-input>
      </div>
    </div>
    <div class="tw-mt-3 tw-mt-5 tw-grid tw-grid-cols-12 tw-gap-x-3">
      <div
        class="mortgage-result tw-col-span-12 tw-flex tw-items-center tw-justify-center tw-gap-x-3 tw-rounded tw-bg-[var(--camel)] tw-p-6 tw-text-center tw-text-white"
      >
        <span class="tw-font-medium"> Monthly repayments: </span>
        <span class="tw-text-xl tw-font-black" style="transition: all 0.5s">
          {{ monthlyRepayment }}
        </span>
      </div>
      <div class="text-center tw-col-span-12 tw-mt-4">
        <lazy-library-text text="Need more info? See our" tag="span" class="tw-mr-1"></lazy-library-text>
        <nuxt-link :to="ROUTES.CONTACT_US" :external="true" class="nc-text tw-cursor-pointer tw-underline"
          >Mortgage guides and calculators.</nuxt-link
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import NcSelect from '~/components/common/inputs/NcSelect.vue';
import NcButton from '~/components/common/inputs/NcButton.vue';
import NcInput from '~/components/common/inputs/NcInput.vue';
import DoughnutChart from '~/components/library/tools/DoughnutChart.vue';
import { calculatePercentageValue, currencyFormat, getPercentage } from '~/helpers';
import { ROUTES } from '~/constants/ui.constants';

export default defineNuxtComponent({
  name: 'MortgageCalculator',
  components: { DoughnutChart, NcInput, NcButton, NcSelect },

  props: {
    defaultPrice: {
      required: false,
      default: 0,
      type: Number,
    },
  },

  data() {
    return {
      model: {
        price: 0,
        deposit_fee: 0,
        annual_interest_rate: 6.01,
        repayment_period: 25,
      },

      depositPercentage: 10,
      isAutoDepositLocked: false,
    };
  },

  computed: {
    ROUTES() {
      return ROUTES;
    },
    monthlyRepayment() {
      const price = this.model.price || 0;
      const depositFee = this.model.deposit_fee || 0;
      const annualInterestRate = this.model.annual_interest_rate || 0;
      const repaymentPeriod = this.model.repayment_period || 0;

      if (!repaymentPeriod) {
        // if period is 0 or null, it results by infinity in below calculation
        return currencyFormat(0);
      }

      const loanAmount = price - depositFee;
      const monthlyInterestRate = annualInterestRate / 100 / 12;
      const totalPayments = repaymentPeriod * 12;

      const monthlyRepayment =
        (loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, totalPayments)) /
        (Math.pow(1 + monthlyInterestRate, totalPayments) - 1);

      return currencyFormat(monthlyRepayment);
    },
  },

  methods: {
    onFeeChanged() {
      const percentage = getPercentage(this.model.deposit_fee, this.model.price);
      // don't get percentage higher than 100
      if (percentage >= 100) {
        this.depositPercentage = 100;
      } else {
        this.depositPercentage = percentage;
      }
    },
  },

  watch: {
    defaultPrice: {
      handler(e) {
        if (e) {
          this.model.price = e;

          if (!this.isAutoDepositLocked) {
            this.model.deposit_fee = calculatePercentageValue(this.depositPercentage, e);
            this.isAutoDepositLocked = true;
          }
        }
      },
      immediate: true,
    },
  },
});
</script>
