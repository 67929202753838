<template>
  <div>
    <lazy-library-title text="Stamp Duty Calculator" tag="h3"></lazy-library-title>
    <div class="tw-mt-5 tw-grid tw-grid-cols-12 tw-gap-x-4">
      <nc-select v-model="model.option" class="tw-col-span-12 md:tw-col-span-6" label="I am..." :options="options" />
      <common-inputs-nc-currency-input
        v-model:price="model.price"
        class="tw-col-span-12 md:tw-col-span-6"
        unique_id="hello"
        label="Property Price"
        :is-currency="true"
      ></common-inputs-nc-currency-input>
    </div>
    <div class="tw-mt-5 tw-w-3/5 md:tw-w-2/5 lg:tw-w-1/5">
      <nc-button label="Calculate" @on-click="onCalculated()"></nc-button>
    </div>
    <div v-if="sdlt" class="stamp-duty-result tw-mt-5 tw-grid tw-grid-cols-12 tw-gap-x-3">
      <div
        class="tw-col-span-12 tw-flex tw-items-center tw-justify-center tw-gap-x-3 tw-rounded tw-bg-[var(--camel)] tw-p-6 tw-text-center tw-text-white"
      >
        <span class="tw-font-medium"> From 23rd Sept 2022: </span>
        <span class="tw-text-xl tw-font-black" style="transition: all 0.5s">
          {{ sdlt }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import NcSelect from '~/components/common/inputs/NcSelect.vue';
import NcButton from '~/components/common/inputs/NcButton.vue';
import { currencyFormat } from '~/helpers';

export default defineNuxtComponent({
  name: 'StampDutyCalculator',
  components: { NcButton, NcSelect },

  props: {
    defaultPrice: {
      required: false,
      default: 0,
      type: Number,
    },
  },

  data() {
    return {
      model: {
        option: 'buying_my_first_home',
        price: 0,
      },

      // stamp duty land tax
      sdlt: '',

      options: [
        {
          title: 'Buying my first home',
          value: 'buying_my_first_home',
        },
        {
          title: 'Buying my next home',
          value: 'buying_my_next_home',
        },
        {
          title: 'Buying an additional home',
          value: 'buying_an_additional_home',
        },
      ],
    };
  },

  methods: {
    onCalculated() {
      const price = this.model.price;
      const option = this.model.option;
      let sdlt = 0;

      if (option === 'buying_my_first_home') {
        if (price <= 300000) {
          sdlt = 0;
        } else if (price <= 500000) {
          sdlt = (price - 300000) * 0.05;
        } else {
          sdlt = 10000 + (price - 500000) * 0.1;
        }
      } else if (option === 'buying_my_next_home') {
        if (price <= 250000) {
          sdlt = 0;
        } else if (price <= 925000) {
          sdlt = (price - 250000) * 0.05;
        } else if (price <= 1500000) {
          sdlt = 33750 + (price - 925000) * 0.1;
        } else {
          sdlt = 78750 + (price - 1500000) * 0.12;
        }
      } else if (option === 'buying_an_additional_home') {
        if (price <= 250000) {
          sdlt = price * 0.03;
        } else if (price <= 925000) {
          sdlt = 7500 + (price - 250000) * 0.08;
        } else if (price <= 1500000) {
          sdlt = 60000 + (price - 925000) * 0.13;
        } else {
          sdlt = 151500 + (price - 1500000) * 0.15;
        }
      }

      this.sdlt = currencyFormat(sdlt);
    },
  },

  watch: {
    defaultPrice: {
      handler(e) {
        if (e) {
          this.model.price = e;
        }
      },
      immediate: true,
    },
  },
});
</script>
